



































import Vue from 'vue'
import moment from 'moment'
const storagePrefix = 'cz.tobacco.h5.'
import BenzAMRRecorder from "benz-amr-recorder"
export default Vue.extend({
    props: {
        oneChat: {type: Object},
        soundsId: {type: String},
        creatorInfo: {type: Object}
    },
    computed: {
        current(): any {
            return this.$store.state.current
        },
    },
    watch: {
        soundsId() {
            if (this.oneChat.chatMode === 4) {
                if (this.amr && this.amr.isPlaying()) {
                    this.amr.stop();
                    this.videoPalySrc = '/static/images/hornMute.png'
                    this.amr = null
                    this.isPalyVideo = false
                }
            }
        },
    },
    data() {
        return {
            isPalyVideo: false,
            videoPalySrc: '/static/images/hornMute.png',
            amr: null,
        }
    },
    methods: {
        checkImg(chatMode, content) {
            this.$emit('checkImg', {chatMode, content})
        },
        clickAudio(id, soundsStr) {
            // this.playVideo()
            this.$emit('getSoundsId', id)
            this.$nextTick(() => {
                this.isPalyVideo = !this.isPalyVideo
                this.playAmr(soundsStr)
            })
        },
        playAmr(soundsStr) {
            // console.log(soundsStr)
            // const audioUrl = '/static/images/003.amr'
            // const audioUrl = 'http://czycwechat.sxktqx.com/chat_files' + soundsStr
            const audioUrl = '/chat_files' + soundsStr
            if (this.amr !== null) {
                this.stopPlayVoice()
            }
            if (this.isPalyVideo) {
                this.amr = new BenzAMRRecorder(); //建立
                this.amr.initWithUrl(audioUrl) //初始化
                .then(() => {
                    this.amr.play(); //播放
                    this.videoPalySrc = '/static/images/hornPlay.gif'
                    this.amr.onEnded(() => {
                        this.videoPalySrc = '/static/images/hornMute.png'
                    });
                }).catch((e) => {
                    this.videoPalySrc = '/static/images/hornMute.png'
                    this.$message.error("播放录音失败");
                })
            }
        },
        stopPlayVoice() {
            if (this.amr.isPlaying()) {
                this.amr.stop();
                this.videoPalySrc = '/static/images/hornMute.png'
                this.amr = null
            }
        },
        playVideo() {
            // console.log(this.isPalyVideo)
            const myAudio: any = document.getElementById('buttonAudio');
            if (this.isPalyVideo) {
                this.videoPalySrc = '/static/images/hornPlayNew.png'
                myAudio.play();
                const content = JSON.parse((this.oneChat.content))
                setTimeout(() => {
                    myAudio.pause();
                    this.videoPalySrc = '/static/images/hornMuteNew.png'
                }, Math.ceil(content.time) * 1000);
            } else {
                this.videoPalySrc = '/static/images/hornMuteNew.png'
                myAudio.pause();
            }
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            if(isToday(time)) {
                return time.format('HH:mm')
            }
            return time.format('YYYY年M月D日 HH:mm')
        }
    },
    destroyed() {
        if (this.amr && this.amr.isPlaying()) {
            this.amr.stop();
            this.videoPalySrc = '/static/images/hornMute.png'
            this.amr = null
        }
    },
})
function isToday(momentDate: moment.Moment) {
    return momentDate.isSame(moment().startOf('day'), 'd')
}
